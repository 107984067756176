import employoLogo from "../../assets/images/employo-logo.png";
const Header = () => {
  return (
    <>
      <nav className="header-section navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={employoLogo} alt={employoLogo} className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link text-white active"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Clients
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Reviews
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  FAQs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact Us
                </a>
              </li>
            </ul>
            <div className="">
              <a
                className="btn-get-started px-3 py-2 fw-semibold d-flex align-items-center justify-content-center gap-1"
                href="#"
              >
                Request a Demo
                <span class="material-symbols-outlined">north_east</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
