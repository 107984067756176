import { API } from "../Request";

export const getAnalysis = () => {
  return API.request({
    url: "get_analysis",
    method: "GET",
  });
};

export const getJobPostAPIID = (id) => {
  return API.request({
    url: `get_analysis_id/${id}`,
    method: "GET",
  });
};

export const getJobPost = () => {
  return API.request({
    url: "get_jobs",
    method: "GET",
  });
};
