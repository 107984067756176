import DashboardLayouts from "../../../components/DashboardLayouts";

const Analytics = () => {
  return (
    <>
      <DashboardLayouts>Analytics</DashboardLayouts>
    </>
  );
};
export default Analytics;
