import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import JobPost from "../pages/dashboard/jobpost";
import DashboardHome from "../pages/dashboard";
import Analytics from "../pages/dashboard/analytics";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="dashboard" element={<DashboardHome />} />
        <Route path="jobs" element={<JobPost />} />
        <Route path="analytics" element={<Analytics />} />
      </Routes>
    </>
  );
};

export default Routing;
