import CardImage from "../assets/images/card-silder.png";
import imageLogo from "../assets/images/image-logo.png";

export const accordionData = [
  {
    title: "Make a last impression with Employo AI",
    content:
      "Discover why hiring managers prefer Employo AI over the competition and what makes it the easiest, most powerful ATS system",
  },
];
export const ScheduleData = [
  {
    img: CardImage,
    title: "Upload Resumes",
    content: "Quickly upload and organize resumes from various sources.",
  },
  {
    img: CardImage,
    title: "AI-Powered Scoring",
    content:
      "Let Employo’s AI analyze and rank candidates based on your specific needs.",
  },
  {
    img: CardImage,
    title: "Detailed Reports",
    content:
      "Receive in-depth analysis and scoring to make informed decisions.",
  },
  {
    img: CardImage,
    title: "Easy Scheduling",
    content: "Schedule interviews effortlessly, directly from the platform.",
  },
];
export const featuresCardData = [
  {
    img: CardImage,
    title: "Resume Parsing",
    content: "Extract critical data from resumes in seconds.",
  },
  {
    img: CardImage,
    title: "Smart Matching",
    content: "AI algorithms match candidates with your job requirements.",
  },
  {
    img: CardImage,
    title: "Customizable Filters",
    content: "Filter candidates based on skills, experience, and more.",
  },
  {
    img: CardImage,
    title: "Collaborative Hiring",
    content: "Share candidate profiles and feedback with your team",
  },
  {
    img: CardImage,
    title: "Diversity Tracking",
    content: "Ensure a diverse pool of candidates.",
  },
  {
    img: CardImage,
    title: "Secure Data Management",
    content: "Protect your data with industry-leading security.",
  },
];

export const clientsData = [
  {
    img: imageLogo,
    ClientsName: "Dr. Christian Grobe",
    position: "CEO",
    reviews:
      "Employo has transformed our recruitment process. The AI scoring is incredibly accurate.",
  },
  {
    img: imageLogo,
    ClientsName: "Dr. Christian Grobe",
    position: "CEO",
    reviews:
      "We’ve saved so much time with Employo’s automated scheduling. A game-changer!",
  },
  {
    img: imageLogo,
    ClientsName: "Dr. Christian Grobe",
    position: "CEO",
    reviews:
      "The detailed candidate reports helped us make better hiring decisions. Highly recommend!",
  },
];
