import testimonialimage from "../../assets/images/testimonials.png";
import user from "../../assets/images/testimonials-user.png";
const TestimonialsCard = ({ logo, reviews, ClientsName, position }) => {
  return (
    <>
      <div className="testimonials mb-5">
        <div className="container">
          <div className="testimonials-card shadow-sm border-1 rounded-3">
            <div className="row gx-0">
              <div className="col-sm-6 col-md-4">
                <img
                  src={testimonialimage}
                  alt="Testimonials"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-6 col-md-8">
                <div>
                  <div className="testimonials-content p-4 mt-4">
                    <div className="testimonials-image">
                      <img src={logo} className="mb-4" alt="image" />
                      <p className="fw-light">{reviews}</p>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img src={user} alt="user" className="img-fluid" />
                      <div>
                        <h6 className="fw-bolder m-0">{ClientsName}</h6>
                        <span className="fw-light">{position}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TestimonialsCard;
