import Select from "react-select";
import { useState } from "react";

import DashboardLayouts from "../../../components/DashboardLayouts";
import PostLineOne from "../../../assets/images/post-job-line.png";
import PostLineTwo from "../../../assets/images/post-job-Lines.png";
import {
  experienceLevels,
  JobTitleTabs,
  optionsBenefits,
  optionsCompensation,
  shiftsSchedule,
  timelineTabs,
} from "./data";

const JobPost = () => {
  const [step, setstep] = useState(1);

  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };
  const [formData, setFormData] = useState({
    title: "",
    workingType: [],
    employees: "",
    positions: "",
    experience: [],
    schedule: [],
    timeline: [],
    minAmount: "",
    maxAmount: "",
    compensation: [],
    benefits: [],
    description: "",
  });

  const handleSelect = (type, id) => {
    setFormData((prevState) => {
      const isSelected = prevState[type].includes(id);
      const newWorkingType = isSelected
        ? prevState[type].filter((existingId) => existingId !== id)
        : [...prevState[type], id];

      return {
        ...prevState,
        [type]: newWorkingType,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeCompensation = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      compensation: selectedValues,
    }));
  };
  const handleChangeBenefits = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      benefits: selectedValues,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "formData");
  };

  switch (step) {
    case 1:
      return (
        <>
          <DashboardLayouts>
            <div className="job-post-section mb-5">
              <div className="job-post-container">
                <div className="job-post-text mt-3 mb-4">
                  <h2 className="fw-semibold">Post Job</h2>
                </div>
                <div className="mb-5">
                  <img
                    src={PostLineOne}
                    alt="PostLineOne"
                    className="img-fluid"
                  />
                </div>
                <div className="post-form">
                  <form>
                    <div className="mb-4">
                      <label className="form-label">Job Title</label>
                      <input
                        type="text"
                        className="form-control shadow-none py-2 mt-2 form-border-color"
                        onChange={handleChange}
                        value={formData.title}
                        name="title"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Working type</label>
                      <div className="post-working-type mt-2">
                        {JobTitleTabs.map((tab) => (
                          <div
                            key={tab.id}
                            onClick={() => handleSelect("workingType", tab.id)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.workingType.includes(tab.id)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="row">
                        <div className="col">
                          <label className="form-label">
                            Any specific location for employees?
                          </label>
                          <select
                            id="inputState"
                            className="form-select shadow-none py-2 mt-2 form-border-color"
                            onChange={handleChange}
                            value={formData.employees}
                            name="employees"
                          >
                            <option selected>Choose...</option>
                            <option>abc</option>
                          </select>
                        </div>
                        <div className="col">
                          <label className="form-label">
                            No of positions to No of positions to fill
                          </label>
                          <input
                            type="number"
                            className="form-control shadow-none py-2 mt-2 form-border-color"
                            onChange={handleChange}
                            value={formData.positions}
                            name="positions"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Experience level</label>
                      <div className="post-working-type mt-2">
                        {experienceLevels.map((tab) => (
                          <div
                            key={tab.id}
                            onClick={() => handleSelect("experience", tab.id)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.experience.includes(tab.id)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Schedule</label>
                      <div className="post-working-type mt-2">
                        {shiftsSchedule.map((tab) => (
                          <div
                            key={tab.id}
                            onClick={() => handleSelect("schedule", tab.id)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.schedule.includes(tab.id)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                        onClick={nextStep}
                      >
                        Continue
                        <span className="material-symbols-outlined">
                          arrow_right_alt
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DashboardLayouts>
        </>
      );
  }
  switch (step) {
    case 2:
      return (
        <>
          <DashboardLayouts>
            <div className="job-post-section mb-5">
              <div className="job-post-container">
                <div className="job-post-text mt-3 mb-4">
                  <h2 className="fw-semibold">Post Job</h2>
                </div>
                <div className="mb-5">
                  <img src={PostLineTwo} alt="PostLine" className="img-fluid" />
                </div>
                <div className="post-form">
                  <div className="mb-4">
                    <label className="form-label">
                      Hiring timeline for the job
                    </label>
                    <div className="post-working-type mt-2">
                      {timelineTabs.map((tab) => (
                        <div
                          key={tab.id}
                          onClick={() => handleSelect("timeline", tab.id)}
                          className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                            formData.timeline.includes(tab.id)
                              ? "active"
                              : "text-dark"
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="material-symbols-outlined">add</span>
                          {tab.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label mb-2">Salary</label>
                    <div className="row">
                      <div className="col">
                        <label className="form-label">Min Amount</label>
                        <input
                          type="text"
                          className="form-control shadow-none py-2 mt-2 form-border-color"
                          onChange={handleChange}
                          value={formData.minAmount}
                          name="minAmount"
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">Max Amount</label>
                        <input
                          type="text"
                          className="form-control shadow-none py-2 mt-2 form-border-color"
                          onChange={handleChange}
                          value={formData.maxAmount}
                          name="maxAmount"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Compensation</label>
                    <div className="post-working-type mt-2">
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={optionsCompensation}
                        onChange={handleChangeCompensation}
                        value={optionsCompensation.filter((option) =>
                          formData.compensation.includes(option.value)
                        )}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Benefits</label>
                    <div className="post-working-type mt-2">
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={optionsBenefits}
                        onChange={handleChangeBenefits}
                        value={optionsBenefits.filter((option) =>
                          formData.benefits.includes(option.value)
                        )}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Job description</label>
                    <textarea
                      type="text"
                      className="form-control shadow-none py-2 mt-2 form-border-color"
                      onChange={handleChange}
                      value={formData.description}
                      name="description"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <button
                      type="button"
                      className="back-outline d-flex align-items-center py-2 px-3"
                      onClick={prevStep}
                    >
                      <span className="material-symbols-outlined">
                        arrow_back_ios
                      </span>
                      Back
                    </button>

                    <button
                      type="submit"
                      className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                      onClick={handleSubmit}
                    >
                      Continue
                      <span className="material-symbols-outlined">
                        arrow_right_alt
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DashboardLayouts>
        </>
      );
  }
};
export default JobPost;
