import CountUp from "react-countup";

const Counter = () => {
  return (
    <>
      <div className="counter-section mb-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2>Numbers speaking for themselves</h2>
          </div>
          <div className="counter text-center">
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="border-end">
                  <CountUp
                    start={0}
                    end={98}
                    formattingFn={(value) => `${value}%`}
                    duration={2}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <p className="fw-medium">Candidate Match Rate</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="border-end">
                  <CountUp
                    start={0}
                    end={10000}
                    formattingFn={(value) => `${value}+`}
                    duration={3}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <p className="fw-medium">Resumes Analyzed</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="">
                  <CountUp
                    start={0}
                    end={500}
                    duration={4}
                    formattingFn={(value) => `${value}+`}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <h2 ref={countUpRef}></h2>
                      </div>
                    )}
                  </CountUp>
                  <p className="fw-medium">Clients Worldwide</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Counter;
