import Routing from "./routing";

const App = () => {
  return (
    <>
      <Routing />
    </>
  );
};

export default App;
