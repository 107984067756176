import { useEffect, useState } from "react";
import DashboardLayouts from "../../components/DashboardLayouts";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { getAnalysis } from "../../api/getJobPost";

const Dashboard = () => {
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "Resume",
      selector: (row) => row.resume,
    },
    {
      name: "Experience",
      selector: (row) => row.experience,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
  ];
  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];  
  // const [data, setData] = useState([]);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getAnalysis());
  // }, []);
  return (
    <>
      <DashboardLayouts>
        <DataTable columns={columns} data={data} />
      </DashboardLayouts>
    </>
  );
};
export default Dashboard;
