import Slider from "react-slick";
import TestimonialsCard from "../../components/TestimonialsCard";
import { useRef } from "react";
import { clientsData } from "../../utils/data";

const Testimonials = () => {
  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container overflow-hidden position-relative">
        <button
          onClick={previous}
          style={{ top: "40%", marginLeft: "-6px" }}
          className="position-absolute z-1 border-0 bg-testimonals text-white rounded-circle d-flex align-items-center justify-content-center py-2 px-2"
        >
          <span class="material-symbols-outlined">chevron_left</span>
        </button>
        <Slider ref={(c) => (slider.current = c)} {...settings}>
          {clientsData.map((item, index) => (
            <TestimonialsCard
              key={index}
              logo={item.img}
              reviews={item.reviews}
              ClientsName={item.ClientsName}
              position={item.position}
            />
          ))}
        </Slider>
        <button
          onClick={next}
          style={{ top: "40%", marginLeft: "-6px" }}
          className="position-absolute end-0 z-1 border-0 bg-testimonals text-white rounded-circle d-flex align-items-center justify-content-center py-2 px-2"
        >
          <span class="material-symbols-outlined">chevron_right</span>
        </button>
      </div>
    </>
  );
};
export default Testimonials;
