import Features from "../../components/Features";
import { featuresCardData } from "../../utils/data";

const EmpowerCard = () => {
  return (
    <>
      <div className="empower-card mb-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="fw-bold">
              Powerful features <br /> tailored to your needs
            </h2>
            <p>
              Gain valuable data-driven insights into talent markets worldwide.
            </p>
          </div>
          <div className="card-empower">
            <div className="row gy-4">
              {featuresCardData.map((item, index) => {
                return (
                  <div className="col-sm-6 col-md-4" key={index}>
                    <Features
                      title={item.title}
                      content={item.content}
                      img={item.img}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmpowerCard;
