const Features = ({ img, title, content }) => {
  return (
    <>
      <div class="card rounded-4 border-0 text-center">
        <div className="text-center">
          <img src={img} class="sider-card-img" alt={img} />
        </div>
        <div class="card-body">
          <h5 class="card-title fw-semibold">{title}</h5>
          <p class="card-text fw-normal">{content}</p>
        </div>
      </div>
    </>
  );
};

export default Features;
