import image1 from "../../assets/images/Ellipse1.png";
import image2 from "../../assets/images/Ellipse2.png";
import image3 from "../../assets/images/Ellipse3.png";
const Banner = () => {
  return (
    <>
      <div className="vh-100">
        <div className="banner-section">
          <div className="banner-bg-section mt-5">
            <div className="banner-top-content mb-3">
              <button>New</button>
              <a
                href=""
                className="d-flex align-items-center justify-content-center"
              >
                Create teams in Organisation
                <span className="material-symbols-outlined ms-2">
                  trending_flat
                </span>
              </a>
            </div>
            <h1 className="mb-3">
              Boost Your Hiring <br />
              Process with Employo AI
            </h1>
            <p className="fw-normal mb-3">
              Revolutionize how you recruit with our AI-driven platform designed
              to find the best talent, fast.
            </p>
            <div className="d-flex align-items-center justify-content-center gap-3 image-bottom mb-4">
              <div>
                <img src={image1} alt="BannerSide" className="img-fluid" />
                <img src={image2} alt="BannerSide" className="img-fluid" />
                <img src={image3} alt="BannerSide" className="img-fluid" />
              </div>
              <small className="fw-medium">Trusted already by 12000+</small>
            </div>
            <div className="banner-btn-section d-flex gap-2">
              <a
                className="btn-more p-2 text-decoration-none rounded-1 px-4 fw-semibold me-2"
                href="#"
              >
                Learn More
              </a>
              <a
                className="d-flex align-items-center justify-content-center gap-2 btn-started p-2 text-decoration-none rounded-1 px-3 fw-semibold"
                href="#"
              >
                Get Started
                <span className="material-symbols-outlined">north_east</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
