import DashboardLayouts from "../../components/DashboardLayouts";

const Dashboard = () => {
  return (
    <>
      <DashboardLayouts>Dashboard</DashboardLayouts>
    </>
  );
};
export default Dashboard;
