import employoLogo from "../../assets/images/employo-logo.png";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Twitter from "../../assets/images/twitter.svg";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-section p-4">
          <div className="container-fluid">
            <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-4 py-3">
              <div className="col mb-3">
                <div className="" style={{ marginLeft: "-24px" }}>
                  <img
                    src={employoLogo}
                    alt={employoLogo}
                    className="img-fluid mb-3"
                  />
                  <p className="fw-light ms-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </p>
                </div>
              </div>

              <div className="col mb-3">
                <h5 className="fw-semibold mb-3">Company</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Features
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col mb-3">
                <h5 className="fw-semibold mb-3">Terms of Service</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Terms and conditions
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-white fw-light">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col mb-3">
                <div className="social-media">
                  <h5 className="fw-semibold mb-3">Social media</h5>
                  <div>
                    <img
                      src={Facebook}
                      alt="facebook"
                      className="img-fluid me-3"
                    />
                    <img
                      src={Instagram}
                      alt="instgram"
                      className="img-fluid me-3"
                    />
                    <img src={Twitter} alt="twitter" className="img-fluid" />
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
        {/* <div className="footer-bottom d-block d-md-flex justify-content-between align-items-center p-2">
          <div>
            <p className="m-md-0 text-white">
              ©2024 EmployoAI ®. All rights reserved worldwide.
            </p>
          </div>
          <div>
            <img src={Facebook} alt="facebook" className="img-fluid me-3" />
            <img src={Instagram} alt="instgram" className="img-fluid me-3" />
            <img src={Twitter} alt="twitter" className="img-fluid" />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
