import { Element } from "react-scroll";
import Accordion from "../../components/Accordion";
import { accordionData } from "../../utils/data.js";
import { useState } from "react";

const Frequently = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <>
      <Element name="FAQs">
        <div className="frequently mb-5">
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="fw-bold">Frequently Asked Questions</h2>
              <p>
                Our Customer Success team is with you for every step of your
                journey with Leapsome.
              </p>
            </div>
            <div className="accordion">
              {accordionData.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    title={item.title}
                    content={item.content}
                    isActive={index === activeIndex}
                    onClick={() => handleClick(index)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};
export default Frequently;
