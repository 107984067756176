import Layouts from "../../components/Layouts";
import Summary from "../summary";
import Banner from "../banner";
import LeaderLogo from "../leaderlogo";
import SiderCard from "../sidercard";
import Candidate from "../candidate";
import RoleWorld from "../role";
import EmpowerCard from "../empowercard";
import Counter from "../counter";
import Testimonials from "../testimonials";
import Frequently from "../frequently";

const Home = () => {
  return (
    <>
      <Layouts>
        <Banner />
        <SiderCard />
        <LeaderLogo />
        <Summary />
        <Candidate />
        <RoleWorld />
        <EmpowerCard />
        <Counter />
        <Testimonials />
        <Frequently />
      </Layouts>
    </>
  );
};
export default Home;
