import Slider from "react-slick";
import logo1 from "../../assets/images/company-logo1.svg";
import logo2 from "../../assets/images/company-logo2.svg";
import logo3 from "../../assets/images/company-logo3.svg";
import logo4 from "../../assets/images/company-logo4.svg";
import logo5 from "../../assets/images/company-logo5.svg";
import logo6 from "../../assets/images/company-logo6.svg";
import logo7 from "../../assets/images/company-logo7.svg";
import logo8 from "../../assets/images/company-logo8.svg";
import { Element } from "react-scroll";

const LeaderLogo = () => {
  var settings = {
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Element name="Clients">
        <div className="leader-logo mb-5">
          <div>
            <div className="text-center mb-4">
              <h2 className="fw-bold text-center">Chosen by Leaders</h2>
              <p>
                Join 500+ companies that have enhanced their hiring process with
                Employo AI.
              </p>
            </div>
            <div className="container-fluid">
              <Slider {...settings} className="banner-slider overflow-hidden">
                <div>
                  <img src={logo1} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo2} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo3} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo4} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo5} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo6} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo7} alt="logo1" className="card-logo" />
                </div>
                <div>
                  <img src={logo8} alt="logo1" className="card-logo" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};
export default LeaderLogo;
