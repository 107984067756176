import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../pages/home";
import JobPost from "../pages/dashboard/jobpost";
import DashboardHome from "../pages/dashboard";
import Analytics from "../pages/dashboard/analytics";
import SignupForm from "../pages/dashboard/signup";
import Login from "../pages/dashboard/login";
import PrivateRoutes from "../utils/PrivateRoute";
import { useEffect } from "react";
import RedirectToHome from "../components/RedirectToHome.js";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="sign-up" element={<SignupForm />} />
        <Route path="login" element={<Login />} />
        <Route path="redirect" element={<RedirectToHome />} />
        <Route exact element={<PrivateRoutes />}>
          <Route path="dashboard" element={<DashboardHome />} />
          <Route path="jobs" element={<JobPost />} />
          <Route path="analytics" element={<Analytics />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
