import React, { useEffect, useState } from "react";
import employoLogo from "../../../assets/images/employo-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signInThunk } from "../../../lib/thunk/user";
import { userAction } from "../../../lib/slice/userSlice";
// import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
// import axios from "axios";
// import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigation = useNavigate();

  const [formData, setFormData] = useState({
    email_or_phone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(userAction.resetMessage(""));
  }, []);

  useEffect(() => {
    if (user?.token) {
      navigation("/jobs");
    }
  }, [user]);

  const validate = () => {
    let tempErrors = {};
    if (
      !formData.email_or_phone ||
      !/\S+@\S+\.\S+/.test(formData.email_or_phone)
    )
      tempErrors.email_or_phone = "Valid email is required";
    if (!formData.password) {
      tempErrors.password = "Password is required";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(signInThunk(formData));
        // setFormData({
        //   email_or_phone: "",
        //   password: "",
        // });
      } catch (error) {
        console.error("Error during submission:", error);
      }
    }
  };
  // const [userData, setUserData] = useState(null);
  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => setUserData(codeResponse),
  //   onError: (error) => console.log("Login Failed:", error),
  // });
  // useEffect(() => {
  //   const performLogin = async () => {
  //     console.log(userData,"userDatauserDatauserData")
  //     if (userData) {
  //       try {
  //         const res = await axios
  //           .get(
  //             "https://ec2-13-200-243-25.ap-south-1.compute.amazonaws.com/login_google",{
  //               headers: {
  //                 Authorization: `Bearer ${userData.access_token}`
  //               }
  //             }
  //           )
  //           .then((response) => {
  //             console.log("response", response);
  //           })
  //           .catch((error) => {
  //             console.log("error", error.toJSON());
  //           });
  //       } catch (error) {
  //         console.error("Login failed:", error);
  //       }
  //     }
  //   };

  //   performLogin();
  //   return () => {
  //     setUserData(null);
  //   };
  // }, [userData]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code"); // Extract the authorization code from the URL

      if (code) {
        try {
          const response = await fetch(
            "https://ec2-13-233-124-155.ap-south-1.compute.amazonaws.com/callback_google_trial?code=" +
              code,
            {
              method: "GET",
            }
          );

          const data = await response.json();
          console.log("Access Token:", data.access_token);
          // Handle access token, save it to state or session storage
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, []);

  return (
    <div className="sign-up">
      <div className="sign-up-section d-block d-md-flex position-relative">
        <div className="left w-100 w-md-50 p-4">
          <div>
            <div className="mb-2">
              <Link className="navbar-brand" to="/">
                <img
                  src={employoLogo}
                  alt={employoLogo}
                  className="img-fluid h-auto"
                  style={{ width: "180px", marginLeft: "-18px" }}
                />
              </Link>
            </div>
            <h2 className="mb-3">AI-Powered Applicant Tracking System (ATS)</h2>
            <p>
              TalentRecruit helps to streamline hiring and effortlessly find,
              hire, and manage top talent. The fastest, smartest, most secure,
              and feature-rich end-to-end recruitment software for hiring!
            </p>
            <ul>
              <li>Employee Onboarding Platform</li>
              <li>AI-Driven Recommendations</li>
              <li>AI Powered Recruiter</li>
              <li>Advanced Analytics & Metrics</li>
            </ul>
          </div>
        </div>
        <div className="right w-100 w-md-50 p-4">
          <div className="p-4">
            <h2 className="mb-4">Login to your account</h2>
            <form>
              <div className="mb-4">
                <div className="row">
                  <div className="col-sm-6 col-md-12">
                    <div className="mb-4">
                      <input
                        type="email"
                        className="form-control shadow-none p-2"
                        id="email_or_phone"
                        name="email_or_phone"
                        value={formData.email_or_phone}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                      {errors.email_or_phone && (
                        <div className="error-message">
                          {errors.email_or_phone}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-12 mb-2">
                      <div className="mb-4">
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control shadow-none p-2"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Password"
                          />
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <span className="material-symbols-outlined">
                                visibility
                              </span>
                            ) : (
                              <span className="material-symbols-outlined">
                                visibility_off
                              </span>
                            )}
                          </button>
                        </div>
                        {errors.password && (
                          <div className="error-message">{errors.password}</div>
                        )}
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                      style={{ background: "#f4812d", border: 0 }}
                      onClick={handleSubmit}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="mt-3 mb-3 text-center" style={{ fontSize: "14px" }}>
              Need an account?
              <Link to="/sign-up" className="ms-2" style={{ color: "#f4812d" }}>
                Sign Up
              </Link>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  className="btn border d-flex align-items-center gap-3 text-dark px-3 py-2"
                  onClick={() => {
                    window.location.href =
                      "https://ec2-13-233-124-155.ap-south-1.compute.amazonaws.com/login_google";
                  }}
                >
                  <img
                    className="img-fluid"
                    style={{ width: "24px", height: "24px" }}
                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                    loading="lazy"
                    alt="google logo"
                  />
                  <span>Login with Google</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
