import talently from "../../assets/images/talently.png";

const Candidate = () => {
  return (
    <>
      <div className="summary-section mb-5">
        <div className="container">
          <div className="summary mb-5">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <img
                  src={talently}
                  alt="framerusercontent"
                  className="img-fluid"
                />
              </div>
              <div className="col-sm-6 col-md-6 d-flex align-items-center justify-content-center flex-column">
                <div className="px-5">
                  <div className="mb-5">
                    <h2 className="fw-bold">
                      Detailed Candidate Scoring and Analysis
                    </h2>
                    <p>Make Informed Hiring Decisions</p>
                  </div>
                  <ul className="p-0">
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Comprehensive candidate profiles with in-depth analysis.
                    </li>
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Performance predictions based on historical data.
                    </li>
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Easy-to-understand scoring to compare top candidates.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Candidate;
