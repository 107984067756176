import { configureStore } from "@reduxjs/toolkit";
import { jobSlice } from "./slice/jobSlice";
import { userSlice } from "./slice/userSlice";

export const store = configureStore({
  reducer: {
    [jobSlice.name]: jobSlice.reducer,
    [userSlice.name]: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
