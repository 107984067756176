import Slider from "react-slick";
import logo1 from "../../assets/images/nttdata.png";
import logo2 from "../../assets/images/oneten.png";
import logo3 from "../../assets/images/ubisoft.png";

const LeaderLogo = () => {
  var settings = {
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="leader-logo mb-5">
        <div>
          <div className="text-center">
            <h2 className="fw-bold text-center">Chosen by Leaders</h2>
            <p>
              Join 500+ companies that have enhanced their hiring process with
              Employo.
            </p>
          </div>
          <div className="container-fluid">
            <Slider {...settings} className="banner-slider overflow-hidden">
              <div>
                <img src={logo1} alt="logo1" className="card-logo" />
              </div>
              <div>
                <img src={logo2} alt="logo1" className="card-logo" />
              </div>
              <div>
                <img src={logo3} alt="logo1" className="card-logo" />
              </div>
              <div>
                <img src={logo3} alt="logo1" className="card-logo" />
              </div>
              <div>
                <img src={logo2} alt="logo1" className="card-logo" />
              </div>
              <div>
                <img src={logo1} alt="logo1" className="card-logo" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};
export default LeaderLogo;
