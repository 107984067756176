import React from "react";
import talently from "../../assets/images/talently.png";

const RoleWorld = () => {
  return (
    <>
      <div className="summary-section mb-5">
        <div className="container">
          <div className="summary mb-5">
            <div className="row">
              <div className="col-sm-6 col-md-6 d-flex align-items-center justify-content-center flex-column">
                <div className="px-5">
                  <div className="mb-5">
                    <h2 className="fw-bold">Interview Scheduling Made Easy</h2>
                    <p>Simplify Your Interview Process</p>
                  </div>
                  <ul className="p-0">
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Integrated calendar for seamless scheduling.
                    </li>
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Automated reminders and notifications.
                    </li>
                    <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                      <span className="material-symbols-outlined">
                        check_circle
                      </span>
                      Coordinate with multiple stakeholders effortlessly.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <img src={talently} className="img-fluid" alt="talently" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleWorld;
