export const optionsBenefits = [
  { value: "Paid time off", label: "Paid time off" },
  { value: "Vision insurance", label: "Vision insurance" },
  { value: "Flexible schedule", label: "Flexible schedule" },
  { value: "Tuition reimbursement", label: "Tuition reimbursement" },
  { value: "Life insurance", label: "Life insurance" },
  { value: "401(k) matching", label: "401(k) matching" },
  { value: "Retirement plan", label: "Retirement plan" },
  { value: "Referral program", label: "Referral program" },
  { value: "Employee discount", label: "Employee discount" },
  { value: "Flexible spending account", label: "Flexible spending account" },
  { value: "Health savings account", label: "Health savings account" },
  { value: "Relocation assistance", label: "Relocation assistance" },
  { value: "Parental leave", label: "Parental leave" },
  {
    value: "Professional development assistance",
    label: "Professional development assistance",
  },
  {
    value: "Employee assistance program",
    label: "Employee assistance program",
  },
  { value: "Other", label: "Other" },
];

export const optionsCompensation = [
  { value: "Bonus opportunities", label: "Bonus opportunities" },
  { value: "Hourly pay", label: "Hourly pay" },
  { value: "Angular", label: "Angular" },
  { value: "RSU", label: "RSU" },
  { value: "Signing bonus", label: "Signing bonus" },
  { value: "Performance bonus", label: "Performance bonus" },
  { value: "Weekly pay", label: "Weekly pay" },
  { value: "Weekly pay", label: "RSU" },
  { value: "Yearly pay", label: "Yearly pay" },
  {
    value: "Employee stock purchase plan",
    label: "Employee stock purchase plan",
  },
  { value: "Yearly bonus", label: "Yearly bonus" },
  {
    value: "Employee stock ownership plan",
    label: "Employee stock ownership plan",
  },
  { value: "Retention bonus", label: "Retention bonus" },
  { value: "Profit sharing", label: "Profit sharing" },
  { value: "Quarterly bonus", label: "Quarterly bonus" },
  { value: "Anniversary bonus", label: "Anniversary bonus" },
  { value: "Stock options", label: "Stock options" },
  { value: "Other", label: "Other" },
];

export const JobTitleTabs = [
  { id: 1, label: "Remote" },
  { id: 2, label: "Hybrid" },
  { id: 3, label: "In-office" },
];
export const experienceLevels = [
  { id: 1, label: "Fresher" },
  { id: 2, label: "1-3 years" },
  { id: 5, label: "3-5 years" },
  { id: 6, label: "5-7 years" },
  { id: 6, label: "7+ years" },
  { id: 7, label: "Any Other" },
];

export const shiftsSchedule = [
  { label: "4 hour shift", id: 1 },
  { label: "8 hour shift", id: 2 },
  { label: "10 hour shift", id: 3 },
  { label: "Monday to Friday", id: 4 },
  { label: "On call", id: 5 },
  { label: "Monday Shift", id: 6 },
  { label: "Overtime", id: 7 },
  { label: "Any Other", id: 8 },
];
export const timelineTabs = [
  { id: 1, label: "1-3 days" },
  { id: 2, label: "3-7 days" },
  { id: 3, label: "1-2 weeks" },
  { id: 4, label: "2-4 weeks" },
  { id: 5, label: "1+ months" },
];
