import { createSlice } from "@reduxjs/toolkit";
import { jobPostThunk } from "../thunk/jobPostThunk";

const initialState = {
  loading: false,
  jobs: [],
  error: "",
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(jobPostThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.jobs = [];
    });
    builder.addCase(jobPostThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.error = "";
      console.log("Job posted successfully: ", action.payload);
    });
    builder.addCase(jobPostThunk.rejected, (state, action) => {
      state.loading = false;
      state.jobs = [];
      state.error = "Error occurred";
      console.log("Job post error: ", action.payload);
    });
  },
});

export const jobAction = jobSlice.actions;
