import profile from "../../assets/images/profile-image.png";
const DashboardHeader = () => {
  return (
    <>
      <nav className="nav-section navbar navbar-expand-lg bg-white border-bottom border-light py-2 position-sticky top-0">
        <div className="container">
          <a className="navbar-brand ms-2" href="#">
            Dashboard
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <form className="position-relative" role="search">
                <input
                  className="form-control ps-5 shadow-none py-2 border-0 bg-body-tertiary"
                  type="search"
                  placeholder="Search here..."
                  aria-label="Search"
                  style={{ fontSize: "14px" }}
                />
                <span
                  className="material-symbols-outlined position-absolute px-3"
                  style={{ color: "#5d5fef", fontSize: "18px", top: "10px" }}
                >
                  search
                </span>
              </form>
            </ul>
            <div className="dropdown">
              <a
                className="nav-item nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                href="/dashboard"
              >
                <img src={profile} className="avatar me-2" alt="Avatar" />
                <span>Musfiq</span>
              </a>
              <ul className="dropdown-menu rounded-0">
                <a href="#" className="dropdown-item">
                  Profile
                </a>
                <a href="#" className="dropdown-item">
                  Calendar
                </a>
                <a href="#" className="dropdown-item">
                  Settings
                </a>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default DashboardHeader;
