import Accordion from "../../components/Accordion";
import {accordionData} from "../../utils/data.js"; 

const Frequently = () => {
  return (
    <>
      <div className="frequently mb-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="fw-bold">Frequently Asked Questions</h2>
            <p>
              Our Customer Success team is with you for every step of your
              journey with Leapsome.
            </p>
          </div>
          <div className="accordion">
            {accordionData.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  title={item.title}
                  content={item.content}
                  defaultOpen={index === 0}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Frequently;
