import React, { useState } from "react";

const Accordion = ({ title, content, defaultOpen = false }) => {
  const [isActive, setIsActive] = useState(defaultOpen);

  return (
    <div className="accordion-section mb-4">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>
          {isActive ? (
            <span className="material-symbols-outlined">remove</span>
          ) : (
            <span className="material-symbols-outlined">add</span>
          )}
        </div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
