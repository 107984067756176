import Select from "react-select";
import { useEffect, useMemo, useState } from "react";
// import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";

import DashboardLayouts from "../../../components/DashboardLayouts";
import PostLineOne from "../../../assets/images/post-job-line.png";
import PostLineTwo from "../../../assets/images/post-job-Lines.png";
import {
  experienceLevels,
  JobTitleTabs,
  optionsBenefits,
  optionsCompensation,
  shiftsSchedule,
  timelineTabs,
} from "./data";
import { useDispatch, useSelector } from "react-redux";
import { jobPostThunk } from "../../../lib/thunk/jobPostThunk";
import { Country, State } from "country-state-city";

const JobPost = () => {
  const [step, setstep] = useState(1);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // const optionsCountry = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const { jobs } = useSelector((state) => state.jobs);
  const [formData, setFormData] = useState({
    title: "",
    workingType: "",
    country: "",
    state: "",
    experience: "",
    schedule: "",
    timeline: "",
    minAmount: "",
    maxAmount: "",
    compensation: [],
    benefits: [],
    description: "",
    positions: "",
    files: [],
  });

  useEffect(() => {
    if (jobs.message == "Job posting added to the database successfully!") {
      navigate("/analytics");
    }
  }, [jobs]);
  // const handleValidation = () => {
  //   const formFields = { ...formData };
  //   const errors = {};
  //   let formIsValid = true;

  //   if (!formFields["title"]) {
  //     formIsValid = false;
  //     errors["title"] = "The job title field is empty. Kindly fill it out.";
  //   }
  //   setErrors(errors);
  //   return formIsValid;
  // };

  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  // const handleSelect = (type, label) => {
  //   setFormData((prevState) => {
  //     const isSelected = prevState[type].includes(label);
  //     const newWorkingType = isSelected
  //       ? prevState[type].filter((existingLabel) => existingLabel !== label)
  //       : [...prevState[type], label];

  //     return {
  //       ...prevState,
  //       [type]: newWorkingType,
  //     };
  //   });
  // };
  const handleSelect = (type, label) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [type]: prevState[type] === label ? "" : label,
      };
    });
  };
  const formatSalary = (value) => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChangeformatSalary = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatSalary(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeCompensation = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      compensation: selectedValues,
    }));
  };
  const handleChangeBenefits = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFormData((prevState) => ({
      ...prevState,
      benefits: selectedValues,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(jobPostThunk(formData));
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  // const handleValidationSumbit = async (e) => {
  //   e.preventDefault();
  //   if (handleValidation()) {
  //     console.log("Form submitted");
  //   } else {
  //     console.error("Form has errors.");
  //   }
  // };

  const handleKeyPress = (e) => {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    console.log("Selected files:", selectedFiles);

    setFormData((prevFormData) => {
      const updatedFiles = [...prevFormData.files, ...selectedFiles];
      console.log("Updated files in state:", updatedFiles);
      return {
        ...prevFormData,
        files: updatedFiles,
      };
    });

    e.target.value = null; // Clear input value for re-selection
  };

  // const downloadFile = (file) => {
  //   const url = URL.createObjectURL(file);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = file.name;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(url);
  // };
  const deleteFile = () => {
    const updatedFiles = formData.files.filter((file) => !file);
    setFormData({ ...formData, files: updatedFiles });
  };

  switch (step) {
    case 1:
      return (
        <>
          <DashboardLayouts>
            <div className="job-post-section mb-5">
              <div className="job-post-container">
                <div className="job-post-text mt-3 mb-4">
                  <h2 className="fw-semibold">Post Job</h2>
                </div>
                <div className="mb-5">
                  <img
                    src={PostLineOne}
                    alt="PostLineOne"
                    className="img-fluid"
                  />
                </div>
                <div className="post-form">
                  <form>
                    <div className="mb-4">
                      <label className="form-label">
                        Job Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                        onChange={handleChange}
                        value={formData.title}
                        name="title"
                      />
                      <span className="text-danger">{errors["title"]}</span>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Working type <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {JobTitleTabs.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelect("workingType", tab.label)
                            }
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.workingType.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="row">
                        <div className="employees-select col">
                          <label className="form-label">
                            Country <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            name="country"
                            value={selectedCountry}
                            onChange={(item) => {
                              setSelectedCountry(item);
                              setFormData((prevData) => ({
                                ...prevData,
                                country: item.name,
                                state: "",
                              }));
                              setSelectedState(null);
                            }}
                            className="form-control shadow-none form-border-color p-0 mt-1"
                          />
                        </div>
                        <div className="employees-select col">
                          <label className="form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={State?.getStatesOfCountry(
                              selectedCountry?.isoCode
                            )}
                            name="state"
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                              setSelectedState(item);
                              setFormData((prevData) => ({
                                ...prevData,
                                state: item.name,
                              }));
                            }}
                            className="form-control shadow-none form-border-color p-0 mt-1"
                          />
                        </div>
                        <div className="col">
                          <label className="form-label">
                            No of positions to fill
                          </label>
                          <input
                            type="text"
                            className="form-control shadow-none py-2 form-border-color"
                            onChange={handleChange}
                            value={formData.positions}
                            name="positions"
                            onKeyPress={handleKeyPress}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Experience level <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {experienceLevels.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelect("experience", tab.label)
                            }
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.experience.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Schedule <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {shiftsSchedule.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelect("schedule", tab.label)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.schedule.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                        onClick={nextStep}
                        // disabled={
                        //   !formData.title ||
                        //   !formData.workingType ||
                        //   !formData.country ||
                        //   !formData.state ||
                        //   !formData.positions ||
                        //   !formData.experience ||
                        //   !formData.schedule
                        // }
                      >
                        Continue
                        <span className="material-symbols-outlined">
                          arrow_right_alt
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DashboardLayouts>
        </>
      );
  }

  switch (step) {
    case 2:
      return (
        <>
          <DashboardLayouts>
            {loading ? (
              <div className="loader">
                <div className="ring"></div>
                <span>loading...</span>
              </div>
            ) : (
              <div className="job-post-section mb-5">
                <div className="job-post-container">
                  <div className="job-post-text mt-3 mb-4">
                    <h2 className="fw-semibold">Post Job</h2>
                  </div>
                  <div className="mb-5">
                    <img
                      src={PostLineTwo}
                      alt="PostLine"
                      className="img-fluid"
                    />
                  </div>
                  <div className="post-form">
                    <div className="mb-4">
                      <label className="form-label">
                        Hiring timeline for the job
                        <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {timelineTabs.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelect("timeline", tab.label)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.timeline.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      {/* <label className="form-label mb-2">
                      Salary <span className="text-danger">*</span>
                    </label> */}
                      <div className="row">
                        <div className="col">
                          <label className="form-label">
                            Min Amount <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control shadow-none py-2 mt-2 form-border-color ps-4"
                              onChange={handleChangeformatSalary}
                              value={formData.minAmount}
                              name="minAmount"
                              onKeyPress={handleKeyPress}
                            />
                            {formData.minAmount.length > 0 && (
                              <span className="position-absolute top-0 py-2 px-2">
                                ₹
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="form-label">
                            Max Amount <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control shadow-none py-2 mt-2 form-border-color ps-4"
                              onChange={handleChangeformatSalary}
                              value={formData.maxAmount}
                              name="maxAmount"
                              onKeyPress={handleKeyPress}
                            />
                            {formData.maxAmount.length > 0 && (
                              <span className="position-absolute top-0 py-2 px-2">
                                ₹
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Compensation <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          options={optionsCompensation}
                          onChange={handleChangeCompensation}
                          value={optionsCompensation.filter((option) =>
                            formData.compensation.includes(option.value)
                          )}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Benefits <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          options={optionsBenefits}
                          onChange={handleChangeBenefits}
                          value={optionsBenefits.filter((option) =>
                            formData.benefits.includes(option.value)
                          )}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Job description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control shadow-none py-2 mt-2 form-border-color"
                        onChange={handleChange}
                        value={formData.description}
                        name="description"
                      />
                    </div>
                    <div className="position-relative">
                      <div className="mb-4 mt-2  upload-btn-wrapper form-control shadow-none text-center">
                        <label className="form-label fw-semibold">
                          <span className="material-symbols-outlined fs-1 d-flex justify-content-center">
                            cloud_upload
                          </span>
                          {formData.files.length > 0 ? (
                            <>
                              <ul style={{ padding: 0 }}>
                                {/* {formData.files.map((file, index) => (
                                <li
                                  key={index}
                                  style={{
                                    listStyle: "none",
                                    fontSize: "12px",
                                  }}
                                >
                                  {file.name}
                                </li>
                              ))} */}
                                {formData.files[0].name}
                                {formData.files.length > 1
                                  ? `+ ${formData.files.length - 1} others`
                                  : ""}
                                <span
                                  className="material-symbols-outlined text-danger"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                  }}
                                  onClick={() => deleteFile()}
                                >
                                  delete
                                </span>
                              </ul>
                            </>
                          ) : (
                            <p>Upload files (Resume or CSV)</p>
                          )}
                        </label>
                        <input
                          type="file"
                          multiple
                          accept=".csv,.pdf,.docx"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <button
                        type="button"
                        className="back-outline d-flex align-items-center py-2 px-3"
                        onClick={prevStep}
                      >
                        <span className="material-symbols-outlined">
                          arrow_back_ios
                        </span>
                        Back
                      </button>

                      <button
                        type="submit"
                        className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                        onClick={handleSubmit}
                        // disabled={
                        //   !formData.timeline ||
                        //   !formData.minAmount ||
                        //   !formData.maxAmount ||
                        //   !formData.compensation ||
                        //   !formData.benefits ||
                        //   !formData.description ||
                        //   formData.files.length === 0
                        // }
                      >
                        Continue
                        <span className="material-symbols-outlined">
                          arrow_right_alt
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DashboardLayouts>
        </>
      );
  }
};
export default JobPost;
