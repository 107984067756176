import { useEffect, useState } from "react";
import { getJobPostAPIID } from "../../api/getJobPost";
import Pagination from "../Pagination";
import image1 from "../../assets/images/Logic_Data_Types.svg";
import image2 from "../../assets/images/Email.svg";
import image3 from "../../assets/images/Internship.svg";
import image4 from "../../assets/images/LinkedIn_card.svg";
import image5 from "../../assets/images/Education.svg";
import image6 from "../../assets/images/Bag.svg";
import image7 from "../../assets/images/Location.svg";
import image8 from "../../assets/images/Development_Skill.svg";
import image9 from "../../assets/images/Resume.svg";

import Add from "../../assets/images/Add.svg";
import Minus from "../../assets/images/Minus.svg";

const AnalyticsCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetailsIndex, setShowDetailsIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const [ranking, setRanking] = useState(null);
  const [location, setLocation] = useState("");
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [showAll, setShowAll] = useState(null);
  const [gender, setGender] = useState({
    male: false,
    female: false,
    other: false,
  });
  const getJobId = localStorage.getItem("jobID");

  const adjustRank = (overallRank) => {
    const descendingArray = [];
    for (let i = 10; i >= 1; i--) {
      descendingArray.push(i);
    }
    return descendingArray[overallRank - 1];
  };

  const filterCandidates = () => {
    const filteredData = data.filter((profileData, index) => {
      if (typeof ranking === "number") {
        return profileData?.Overall_10 >= ranking;
      }

      if (
        location &&
        !profileData.Location.toLowerCase().includes(location.toLowerCase())
      ) {
        return false;
      }

      if (skills) {
        const requiredSkills = skills
          .split(",")
          .map((skill) => skill.trim().toLowerCase())
          .filter((skill) => skill.length > 0);
        const filteredItems = profileData.Skills.filter((item) => {
          return requiredSkills.some((skill) => {
            const itemName = String(item)
              .toLowerCase()
              .substring(0, skill.length);
            const isSame = itemName === skill;
            return isSame;
          });
        });
        if (filteredItems.length === 0) {
          return false;
        }
      }

      if (experience) {
        const exp = parseInt(experience, 10);

        if (profileData.Experience < exp) {
          return false;
        }
      }
      // profileGender

      const profileGender = profileData.Gender.toLowerCase();
      if (
        (gender.male && profileGender !== "male") ||
        (gender.female && profileGender !== "female") ||
        (gender.other && profileGender !== "other")
      ) {
        return false;
      }

      return true;
    });
    return filteredData;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getJobPostAPIID("trial02d");
      setLoading(false);
      const data = response.data;
      if (data) {
        setData(data.scores);
      }
    } catch (error) {
      console.log("!!! analytics card", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filterCandidates().slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filterCandidates().length / recordsPerPage);

  useEffect(() => {
    const bannerSection = document.getElementById("analtices_new");
    if (bannerSection) {
      const topOffset =
        bannerSection.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <div className="analtices-new-section" id="analtices_new">
        <div className="analtices-sec">
          <div className="row">
            <div className="col-sm-6 col-md-9">
              {loading ? (
                <div className="loader">
                  <div className="ring"></div>
                  <span>loading...</span>
                </div>
              ) : currentRecords.length > 0 ? (
                currentRecords.map((profileData, index) => (
                  <div
                    className="profile-card shadow-sm rounded border mb-3"
                    key={index}
                  >
                    <div className="p-2 px-3 d-flex justify-content-between align-items-center border-bottom">
                      <h4 className="profile-name mb-0">{profileData.Name}</h4>
                      <p className="score-value m-0">
                        {profileData.Overall_10 &&
                          `Rank - ${adjustRank(profileData.Overall_10)}`}
                      </p>
                    </div>
                    <div className="p-2 px-3 border-bottom">
                      <div className="profile-scores mb-2">
                        <div className="row">
                          {profileData.Overall_10 && (
                            <div className="col">
                              <p className="score-value gap-1">
                                <img
                                  src={image1}
                                  alt="image1"
                                  className="img-fluid"
                                />
                                Overall Score :
                                <span className="ms-1 text-dark">{`${profileData.Overall_10}/10`}</span>
                              </p>
                            </div>
                          )}

                          {profileData.Experience_Score && (
                            <div className="col">
                              <p className="score-value gap-1">
                                <img
                                  src={image3}
                                  alt="image1"
                                  className="img-fluid"
                                />
                                Experience Score :
                                <span className="ms-1 text-dark">{`${profileData.Experience_Score}/10`}</span>
                              </p>
                            </div>
                          )}

                          {profileData.Skill_Score && (
                            <div className="col">
                              <p className="score-value">
                                <img
                                  src={image9}
                                  alt="image1"
                                  className="img-fluid"
                                />
                                Skill Score :
                                {profileData.Skill_Score && (
                                  <span className="ms-1 text-dark">{`${profileData.Skill_Score}/10`}</span>
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="location-info mb-3">
                        <div className="row">
                          {profileData.Email && profileData.Email !== "N/A" && (
                            <div className="col">
                              <img
                                src={image2}
                                alt="email"
                                className="img-fluid"
                              />
                              <span>{profileData.Email}</span>
                            </div>
                          )}
                          {profileData.Location &&
                            profileData.Location !== "N/A" && (
                              <div className="col">
                                <img
                                  src={image7}
                                  alt="location"
                                  className="img-fluid"
                                />
                                <span>{profileData.Location}</span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="contact-info mb-3">
                        <div className="row">
                          {profileData.Experience !== "N/A" && (
                            <div className="col">
                              <>
                                <img
                                  src={image6}
                                  alt="image6"
                                  className="img-fluid"
                                />
                                <span>{`${parseInt(
                                  profileData.Experience
                                )} years`}</span>
                              </>
                            </div>
                          )}
                          {profileData.Education &&
                            profileData.Education !== "N/A" && (
                              <div className="col">
                                <img
                                  src={image5}
                                  alt="Education"
                                  className="img-fluid"
                                />
                                <span style={{ textTransform: "capitalize" }}>
                                  {profileData.Education}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="location-info mb-3">
                        <div className="row">
                          {profileData.Links.LinkedIn_Links[0] && (
                            <div className="col">
                              <img
                                src={image4}
                                alt="LinkedIn"
                                className="img-fluid"
                              />
                              <a
                                href={profileData.Links.LinkedIn_Links[0]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                                style={{ color: "#5d5fef" }}
                              >
                                {profileData.Links.LinkedIn_Links[0]}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-1 skills-section">
                        <div className="d-flex align-items-md-baseline flex-wrap mt-2">
                          {profileData.Skills.length > 0 && (
                            <p className="score-value">
                              <img
                                src={image8}
                                alt="skills"
                                className="img-fluid"
                              />
                              Skills :
                            </p>
                          )}

                          {profileData.Skills.slice(
                            0,
                            showAll === index ? profileData.Skills.length : 4
                          ).map((skill, i) => (
                            <small
                              key={i}
                              className="badge bg-light me-2 mb-2 ms-2"
                            >
                              {skill}
                            </small>
                          ))}

                          {profileData.Skills.length > 4 && (
                            <button
                              onClick={() =>
                                setShowAll(showAll === index ? null : index)
                              }
                              className="btn btn-link p-0 text-decoration-none"
                            >
                              {showAll === index ? (
                                <img
                                  src={Minus}
                                  alt="Minus"
                                  className="img-fluid"
                                />
                              ) : (
                                <img
                                  src={Add}
                                  alt="Add"
                                  className="img-fluid"
                                />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="button-section d-flex justify-content-around py-3">
                      <button
                        className="know"
                        onClick={() =>
                          setShowDetailsIndex(
                            showDetailsIndex === index ? null : index
                          )
                        }
                      >
                        {showDetailsIndex === index
                          ? "Click to hide Summary"
                          : "Click to view Summary"}
                      </button>
                      <button
                        className="invite "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Notes
                      </button>

                      <div className="dropdown">
                        <button
                          className="short btn   dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">
                              Invite to interview
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Shortlist
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Not interested
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog  modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Candidate Name
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              lorem ipsum dolor sit amet consectetur adipiscing
                              elitlorem ipsum dolor sit amet consectetur
                              adipiscing elitlorem ipsum dolor sit amet
                              consectetur adipiscing elitlorem ipsum dolor sit
                              amet consectetur adipiscing elit
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showDetailsIndex === index && (
                      <div className="p-3 skills-summary">
                        <h6>
                          <strong>Candidate Summary</strong>
                        </h6>
                        <div>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: profileData.Summary,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No data found.</p>
              )}
              {filterCandidates().length > 0 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
            <div className="col-sm-6 col-md-3 border p-0 rounded h-100">
              <h4 className="fw-semibold border-bottom p-2 text-center">
                Filters
              </h4>
              <div className="px-3 mb-2 py-1">
                <div className="mb-4">
                  <h6>Ranking</h6>
                  <div className="d-flex justify-content-around">
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 9}
                          id="ranking10"
                          value={9}
                          onChange={(e) => setRanking(9)}
                        />
                        <label className="form-check-label" htmlFor="ranking10">
                          9 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 8}
                          id="ranking09"
                          value={8}
                          onChange={(e) => setRanking(8)}
                        />
                        <label className="form-check-label" htmlFor="ranking09">
                          8 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 7}
                          id="ranking08"
                          value={7}
                          onChange={(e) => setRanking(7)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          7 & Above
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 6}
                          id="ranking08"
                          value={6}
                          onChange={(e) => setRanking(6)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          6 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 5}
                          id="ranking08"
                          value={5}
                          onChange={(e) => setRanking(5)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          5 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 4}
                          id="ranking08"
                          value={4}
                          onChange={(e) => setRanking(4)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          4 & Above
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h6>Locations</h6>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Enter locations..."
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <h6>Skills</h6>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="E.g.  Java"
                    value={skills}
                    onChange={(e) => setSkills(e.target.value)}
                  />
                </div>

                {/* <div className="mb-4">
                  <h6>Gender</h6>
                  <div className="form-check">
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      name="male"
                      id="male"
                      checked={gender.male}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setGender({
                          male: isChecked,
                          female: false,
                          other: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="male">
                      Male
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      name="female"
                      id="female"
                      checked={gender.female}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setGender({
                          female: isChecked,
                          male: false,
                          other: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="female">
                      Female
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      name="other"
                      id="other"
                      checked={gender.other}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setGender({
                          male: false,
                          female: false,
                          other: isChecked,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                  </div>
                </div> */}
                <div className="mb-4">
                  <h6>Experience</h6>
                  <input
                    type="number"
                    className="form-control shadow-none"
                    placeholder="Enter experience..."
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                    min="1"
                  />
                </div>
                <div className="button-section text-center fw-semibold">
                  <button
                    className="invite"
                    onClick={() => {
                      setRanking(null);
                      setLocation("");
                      setSkills("");
                      setExperience("");
                      setGender({ male: false, female: false, other: false });
                    }}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticsCard;
