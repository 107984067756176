import { createAsyncThunk } from "@reduxjs/toolkit";
import { jobPostAPI } from "../../api/jobPostAPI";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export const jobPostThunk = createAsyncThunk(
  "jobPostThunk",
  async (data, thunkAPI) => {
    console.log(data, "!!!!!!!data post thunk");
    const jobID = uuidv4();
    const jobData = new FormData();
    jobData.append("jobID", jobID);
    jobData.append("jobTitle", data.title);
    jobData.append("working_type", data.workingType);
    jobData.append("country", data.country);
    jobData.append("state", data.state);
    jobData.append("experience_level", data.experience);
    jobData.append("schedule", data.schedule);
    jobData.append("timeline", data.timeline);
    jobData.append("salary_min", data.minAmount);
    jobData.append("salary_max", data.maxAmount);
    jobData.append("compensation", JSON.stringify(data.compensation));
    jobData.append("benefits", JSON.stringify(data.benefits));
    jobData.append("jobDescription", data.description);
    jobData.append("no_of_positions", data.positions);
    const files = Array.from(data.files);
    files.forEach((file) => jobData.append("resumes", file));

    try {
      const response = await jobPostAPI(jobData);
      console.log(response, "!!!! jobPostThunk");
      if (
        response.data.message ==
        "Job posting added to the database successfully!"
      ) {
        localStorage.setItem("jobID", jobID);
        toast.success(response.data.message);
        return response.data;
      }else{
        throw new Error(response.data);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);
